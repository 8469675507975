<template>
  <div :class="theme">
    <router-view/>
    <div style="width: 100%;height: 100%;background-color: var(--deepBg);position: absolute;left: 0;top: 0;z-index: -99"></div>
  </div>
</template>

<script>

export default {
  name: 'App',
  computed: {
    theme() {
      return this.$store.state.theme ? 'White' : 'Dark'
    }
  }
}
</script>

<style>
.Dark {
  --lightText: #aaaaaa;
  --deepText: #dddddd;
  --btn: royalblue;
  --btnActive: dodgerblue;
  --btnText: #dddddd;
  --btnHover: #444444;
  --hover: #2b2c36;
  --inputBg: #333341;
  --inputBorder: rgba(0, 0, 0, 0);
  --lightBg: #1e1f26;
  --deepBg: #000000;
  --th: rgb(134, 134, 136);
  --placeholder: #777777;
  --leftSe: #11121e;
  --divider: #444444;
  --menuBg: #22222299;
  --menuBorder: #333333;
  --danger: #ef403d;
  --dangerHover: #FF6060;
  --success: #22ad1f;
  --successHover: #3bcc38;
  --resource: #9f892e;
  --resourceHover: #c7aa2a;
}

.White {
  --lightText: #333333;
  --deepText: #000000;
  --btn: royalblue;
  --btnActive: dodgerblue;
  --btnText: #ffffff;
  --btnHover: #bbbbbb;
  --hover: #e5e6eb;
  --inputBorder: #333333;
  --inputBg: #DBD7D6;
  --lightBg: #f2f3f5;
  --deepBg: #ffffff;
  --th: rgb(134, 134, 136);
  --placeholder: #777777;
  --leftSe: #eeeeee;
  --divider: #bbbbbb;
  --menuBg: #FFFFFFBB;
  --menuBorder: #00000000;
  --danger: #FF6060;
  --dangerHover: #ef403d;
  --success: #25D120;
  --successHover: #51ee4e;
  --resource: #c4aa04;
  --resourceHover: #d3bc61;
}
</style>
