import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from "@/router"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/global.css'
import request from "@/utils/request"
import store from './store'
import md5 from "js-md5"
import * as echarts from 'echarts'

//取消生产提示的显示
Vue.config.productionTip = false
//使用路由
Vue.use(VueRouter)
//使用element-ui
Vue.use(ElementUI, {size: "small", theme: 'normal'})
//设置配置后的axios
Vue.prototype.request = request
//引入md5加密
Vue.prototype.md5 = md5
//引入echarts
Vue.prototype.$echarts = echarts


//点击其他区域关闭菜单
document.addEventListener('click', e => {
    e.stopPropagation();
    const menu = document.querySelector('.menu');
    if(menu!==null){
        if (!menu.contains(e.target)) {
            v.$bus.$emit('showMenu', 0, 0, false)
        }
    }
})


const v = new Vue({
    render: h => h(App),
    router,
    store,
    //安装事件总线
    beforeCreate() {
        Vue.prototype.$bus = this
    }
}).$mount('#app')
