import VueRouter from "vue-router"
import store from "@/store";


export const router = new VueRouter({
    routes: [
        {
            path: '/',
            name: 'Container',
            redirect: '/login',
            component: () => import('@/components/Container'),
        },
        {
            path: '/404',
            name: 'NotFound',
            component: () => import('@/pages/404')
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('@/pages/Login')
        }
    ],
    mode: 'hash'
})


router.beforeEach((to, from, next) => {

    //如果需要通过守卫
    if (store.state.need) {
        //登录和宣传页、404页面不添加至记录中
        if (to.fullPath !== '/login' && to.fullPath !== '/home-page' && to.fullPath !== '/404') {
            store.commit("addVisited", to.fullPath)
        }
    } else {
        //前进或者后退，修改need为true
        store.state.need = true
    }
    //阻止原地踏步
    if (to.path === from.path) {
        next(false)
    }

    //如果输入不存在的路由，跳转至404页面
    if (!to.matched.length) {
        next('/404')
    }
    next()
})

export const setRoutes = () => {
    //如果已登录则添加动态路由
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    if (user) {
        const dynamicRoutes = [
            {
                path: '/home',
                name: 'Home',
                component: () => import('@/pages/Home')
            },
            {
                path: '/medication',
                name: 'Medication',
                component: () => import('@/pages/control/medicine/Medication')
            },
            {
                path: '/medication-records',
                name: 'MedicineRecords',
                component: () => import('@/pages/control/medicine/MedicationRecords')
            }, {
                path: '/medication-list',
                name: 'MedicineReminders',
                component: () => import('@/pages/control/medicine/MedicationList')
            },
            {
                path: '/mine',
                name: 'Mine',
                component: () => import('@/pages/Mine')
            },
            {
                path: '/message',
                name: 'Message',
                component: () => import('@/pages/Message')
            },
            {
                path: '/home-data',
                name: 'CareHomeData',
                component: () => import('@/pages/control/careHome/CareHomeData')
            },
            {
                path: '/elder',
                name: 'Elder',
                component: () => import('@/pages/control/Elder')
            },
            {
                path: '/log',
                name: 'Log',
                component: () => import('@/pages/control/Log')
            },
            {
                path: '/attendance',
                name: 'Attendance',
                component: () => import('@/pages/control/Attendance')
            }
        ]
        dynamicRoutes.forEach(route => {
            if (!router.getRoutes().some(existingRoute => existingRoute.name === route.name)) {
                router.addRoute('Container', route);
            }
        })
    }
}
//加载时添加动态路由
setRoutes()


const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


export default router